<template>
  <div>
    <div class="card mb-1">
      <div class="card-body">
        <form class="m-0" ref="formFilter" @submit.prevent="submitFilter">
          <div class="form-row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Tag Tema</label>
                <v-select
                  ref="selectTema"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTema.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group mb-md-0">
                <label for="">Período de cadastro</label>
                <flat-pickr
                  v-model="filters.rangeDate"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group mb-md-0">
                <label for="">Professor</label>
                <v-select
                  ref="selectTeacher"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.teacher"
                  placeholder="Digite o nome do professor"
                  :options="optionsTeachers"
                  @search="fetchTeachers"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTeacher.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-group mb-md-0">
                <label for="">Título do material</label>
                <input v-model="filters.title" class="form-control" />
              </div>
            </div>
            <div class="col-md-2">
              <label for="" style="visibility: hidden">Buscar</label>
              <button
                type="submit"
                :disabled="submitedFilter"
                class="btn btn-primary btn-block"
              >
                <div v-if="submitedFilter">
                  <b-spinner label="Loading..." small />
                </div>
                <div v-else>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer cursor"
                    size="16"
                  />
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(title)="data">
          <a
            :title="data.item.title"
            :href="data.item.url"
            target="_blank"
            class="d-flex-left"
          >
            <feather-icon class="mr-50" icon="ExternalLinkIcon" size="13" />
            {{ data.item.title }}
          </a>
        </template>

        <template #cell(classes)="data">
          <b-badge variant="light-dark" class="text-capitalize">
            {{ data.item.classes }}
          </b-badge>
        </template>

        <template #cell(themes)="data">
          <b-badge
            variant="primary"
            class="text-capitalize"
            v-if="data.item.themes"
          >
            {{ data.item.themes }}
          </b-badge>
          <span v-else>-</span>
        </template>
        <template #cell(extension)="data">
          <i
            class="bi"
            style="font-size: 1.4rem"
            :class="`bi-filetype-${data.item.extension.toLowerCase()}`"
          ></i>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="detailsMaterial(data.item.uuid)"
            />

            <router-link
              :to="{
                name: 'materials-v2-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-material"
      modal-class="modal-create"
      title="Cadastrar material"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <form
        ref="formCreateUser"
        enctype="multipart/form-data"
        @submit.prevent="submitUpload"
      >
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título
              </label>
              <input
                require
                type="text"
                :class="{ 'is-invalid': $v.item.title.$error }"
                v-model="item.title"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Arquivo
              </label>
              <input
                style="padding: 0.4rem 0.5rem"
                type="file"
                id="file"
                ref="file"
                class="form-control"
                :class="{ 'is-invalid': $v.item.file.$error }"
                @change="handleFileUpload()"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Professor(a)
              </label>
              <v-select
                ref="selectTeacher"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.teacher"
                placeholder="Digite o título do material"
                :options="optionsTeacher"
                @search="fetchTeachersOptions"
                :class="{ 'is-invalid': $v.item.teacher.$error }"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectTeacher.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <i class="text-danger bi bi-record-circle"></i>
              <label for=""> Grade </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.grid"
                placeholder="Digite a grade para o material"
                :options="optionsGrids"
                @search="fetchGridsOptions"
                :class="{ 'is-invalid': $v.item.grid.$error }"
              >
              </v-select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <i class="text-danger bi bi-record-circle"></i>
              <label for=""> Disciplinas </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.discipline"
                placeholder="Selecione uma disciplina"
                :options="optionsDisciplines"
                :class="{ 'is-invalid': $v.item.discipline.$error }"
              >
              </v-select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <i class="text-danger bi bi-record-circle"></i>
              <label for=""> Aula </label>
              <v-select
                label="title"
                item-text="title"
                item-value="id"
                v-model="item.lesson"
                placeholder="Selecione uma lição"
                :options="optionsLessons"
                :class="{ 'is-invalid': $v.item.lesson.$error }"
              >
                <template
                  v-slot:option="{
                    teacher_name,
                    title,
                    number,
                    date,
                    start_time,
                    end_time,
                  }"
                >
                  <b-row align-v="center">
                    <b-col cols="12">
                      <b-badge variant="primary">Aula {{ number }}</b-badge>
                      <b-badge variant="info" class="ml-1">{{
                        new Date(date).toLocaleDateString("pt-BR")
                      }}</b-badge>
                      <span class="ml-1 text-muted">
                        {{ start_time }} - {{ end_time }}
                      </span>
                      <br />
                      <b-icon-person class="text-primary"></b-icon-person>
                      <span class="font-weight-bold ml-1">{{
                        teacher_name || "Sem Professor"
                      }}</span>
                      -
                      <span>{{ title }}</span>
                    </b-col>
                  </b-row>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end mt-2">
          <div class="col-md-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              block
              @click="hideCreateModal()"
            >
              Fechar
            </b-button>
          </div>
          <div class="col-md-4">
            <button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn btn-success btn-block"
              type="submit"
              :disabled="submited"
            >
              <div v-if="submited">
                <b-spinner small variant="light" /> Verificando...
              </div>
              <div v-else>Cadastrar</div>
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-material-details"
      modal-class="modal-create"
      title="Detalhes do material"
      centered
      hide-footer
    >
      <b-row class="form-row">
        <div class="col-12">
          <div class="form-row mb-2">
            <div
              v-if="
                details.extension == 'png' ||
                details.extension == 'jpg' ||
                details.extension == 'jpeg' ||
                details.extension == 'webp'
              "
              class="col-md-3 text-center"
            >
              <b-img-lazy class="my-1" :src="details.url" rounded fluid />
            </div>
            <div class="col-md-9">
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título</label>
                    <a :href="details.url" target="_blank">
                      <feather-icon
                        icon="ExternalLinkIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      {{ details.title }}
                    </a>
                  </div>
                  <div class="form-group">
                    <label for="">Infos</label>
                    <strong>Size</strong>: {{ details.size }}kb -
                    <strong>Host</strong>: {{ details.driver }} -
                    <strong>Extensão</strong>: {{ details.extension }}
                  </div>
                  <div class="form-group">
                    <strong>Professor</strong>:
                    {{ details.teacher ? details.teacher.title : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <legend style="font-size: 1.3rem">Tags</legend>
          <b-tabs pills fill>
            <b-tab v-for="(tag_category, index) in details.tags" :key="index">
              <template #title>
                <feather-icon icon="HashIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-none d-sm-inline">
                  {{ index === "subject" ? "Tema" : "" }}
                  {{ index === "contest" ? "Concurso" : "" }}
                  {{ index === "timeline" ? "Linha do tempo" : "" }}
                </span>
              </template>
              <b-card class="mb-0">
                <b-badge
                  v-for="(tag, i) in tag_category"
                  :key="i"
                  pill
                  variant="light-primary"
                  style="margin-right: 1px"
                >
                  {{ tag.title }}
                </b-badge>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BImgLazy,
  BCard,
  BBadge,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BPagination,
  BTooltip,
  BFormRow,
  BSidebar,
  BFormFile,
  BSpinner,
  BModal,
  BTabs,
  BTab,
  BIconPerson,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import { required } from "vuelidate/lib/validators";
import FileIcon from "@/views/components/icon-file/Icon";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  directives: {
    Ripple,
  },
  components: {
    CustomPaginateTable,
    BTabs,
    BImgLazy,
    BTab,
    BSidebar,
    BAvatar,
    BModal,
    BSpinner,
    BFormFile,
    BForm,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    FileIcon,
    BIconPerson,
    BBadge,
  },
  validations: {
    item: {
      title: {
        required,
      },
      file: {
        required,
      },
      teacher: {
        required,
      },
      grid: {
        required,
      },
      discipline: {
        required,
      },
      lesson: {
        required,
      },
    },
  },
  data() {
    return {
      details: {},
      item: {
        title: "",
        file: null,
        media_type_id: 1,
        teacher: [],
        grid: null,
        discipline: null,
        lesson: null,
      },
      submited: false,
      submitedFilter: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      dataItems: [],
      tableColumns: [
        {
          key: "title",
          label: "Título",
          sortable: false,
        },
        {
          key: "themes",
          label: "Tema",
          sortable: false,
        },
        {
          key: "extension",
          label: "Extensão",
          sortable: false,
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 110px",
        },
      ],
      optionsTeachers: [],
      optionsTeacher: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      optionsGrids: [],
      optionsDisciplines: [],
      optionsLessons: [],
      filters: {
        tag_subject: [],
        rangeDate: null,
        teacher: "",
        title: "",
        type: 1,
      },
    };
  },
  watch: {
    "item.grid"(newGrid) {
      if (newGrid) {
        this.fetchGridDisciplines(newGrid.code);
      } else {
        this.optionsDisciplines = [];
        this.optionsLessons = [];
      }
    },

    "item.discipline"(newDiscipline) {
      if (newDiscipline) {
        this.fetchLessons(newDiscipline.code, this.item.grid.code);
      } else {
        this.optionsLessons = [];
      }
    },
  },
  computed: {
    ...mapState("Material", ["materials", "material"]),
    ...mapState("Teacher", ["teachers", "searchTeachers"]),
    ...mapState("Tag", ["searchTagsTema"]),
  },
  methods: {
    clearForm() {
      this.item = {
        title: "",
        file: null,
        media_type_id: 1,
        teacher: [],
      };
    },
    submitFilter() {
      this.getData();
    },
    detailsMaterial(uuid) {
      this.$store.dispatch("Material/show", uuid).then(() => {
        this.$bvModal.show("modal-material-details");

        this.details = {
          title: this.material.data.title,
          url: this.material.data.url,
          extension: this.material.data.extension,
          teacher: this.material.data.teacher,
          driver: this.material.data.driver,
          size: this.material.data.size,
          tags: {
            subject: this.material.data.tags.subject,
          },
        };
      });
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    async fetchTeachersOptions(term) {
      if (term.length > 2) {
        this.optionsTeacher = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    async fetchTeachers(term) {
      if (term.length > 2) {
        this.optionsTeachers = await this.$store.dispatch(
          "Teacher/search",
          term
        );
      }
    },
    handleFileUpload() {
      this.item.file = this.$refs.file.files[0];
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name.replaceAll(" ", "-").toLowerCase();
      }
      return `${files.length} arquivos selecionados`;
    },
    submitUpload() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);
      formData.append("title", this.item.title);
      formData.append("teacher", this.item.teacher.code);
      formData.append("media_type_id", this.item.media_type_id);
      formData.append("lesson_id", this.item.lesson ? this.item.lesson.id : "");
      formData.append(
        "discipline_id",
        this.item.discipline ? this.item.discipline.id : ""
      );
      formData.append("ead", 0);

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Material/create", formData)
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            this.getData();
            this.$bvModal.hide("modal-create-material");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
      this.submited = false;
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
        rangeDate: this.filters.rangeDate ?? "",
        teacher: this.filters.teacher ? this.filters.teacher.code : "",
        type: this.filters.type ?? "",
        title: this.filters.title ?? "",
        ead: 0,
      };

      this.$store
        .dispatch("Material/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then(() => {
          if (this.materials) {
            this.dataItems = this.materials.data;
            this.rowsTable = this.materials.data.length;
            this.totalRows = this.materials.meta.total;
            this.currentPage = this.materials.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    async fetchGridsOptions(term) {
      if (term.length > 2) {
        this.optionsGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    async fetchGridDisciplines(grid_id) {
      try {
        const disciplines = await this.$store.dispatch("Grid/disciplines", {
          grid_id,
        });
        this.optionsDisciplines = disciplines;
      } catch (error) {
        console.error("Erro ao buscar disciplinas:", error);
      }
    },
    async fetchLessons(discipline_id, grid_id) {
      try {
        const lessons = await this.$store.dispatch("Grid/disciplineLessons", {
          discipline_id,
          grid_id,
        });
        this.optionsLessons = lessons;
      } catch (error) {
        console.error("Erro ao buscar lições:", error);
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
    },
    hideCreateModal() {
      this.$bvModal.hide("modal-create-material");
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o material caso não tenha nenhum vinculo com outros registros.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Material/delete", { uuid: uuid })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
            })
            .catch(() => {
              this.notifyDefault("error");
            });
        }
      });
    },
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.optionsGrids = await this.$store.dispatch("Grid/search");
    this.$root.$on("open:modal-create-material", () => {
      this.clearForm();
      this.$bvModal.show("modal-create-material");
    });
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-material");
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
